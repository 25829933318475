<div class="layout-sidebar">
    <a [routerLink]="['/']" class="logo">
        <img alt="logo" src="assets/logo/logo.png">
    </a>

    <div class="layout-menu-container">
        <div class="layout-menu">

            <div class="menu-items">

                <a [routerLink]=" ['/dashboard/dashboard']" routerLinkActive="router-link-exact-active">
                    <fa-icon icon="chart-line"></fa-icon>
                    Dashboard
                </a>

                <a (click)="toggleSubmenu($event, '/dashboard/calls')">
                    <fa-icon icon="phone"></fa-icon>
                    Reps
                </a>
                <div [@submenu]="isSubmenuActive('/dashboard/calls') ? 'visible': 'hidden'">
                    <ul>
                        <li>
                            <a [routerLink]=" ['/dashboard/reps']"
                               routerLinkActive="router-link-exact-active"
                               [routerLinkActiveOptions]="{exact:true}">
                                Manage Reps
                            </a>
                        </li>
                        <li>
                            <a [routerLink]=" ['/dashboard/calls']"
                               routerLinkActive="router-link-exact-active"
                               [routerLinkActiveOptions]="{exact:true}">
                                Manage Call Cycle
                            </a>
                        </li>
                        <li>
                            <a [routerLink]=" ['/dashboard/rep-call-report']"
                               routerLinkActive="router-link-exact-active"
                               [routerLinkActiveOptions]="{exact:true}">
                                Rep Call Report
                            </a>
                        </li>
                    </ul>
                </div>

                <a [routerLink]=" ['/dashboard/mileage']" routerLinkActive="router-link-exact-active">
                    <fa-icon icon="truck"></fa-icon>
                    Mileage
                </a>

                <div>
                    <a (click)="toggleSubmenu($event, '/dashboard/surveys')">
                        <fa-icon icon="barcode"></fa-icon>
                        Surveys
                    </a>

                    <div [@submenu]="isSubmenuActive('/dashboard/surveys') ? 'visible': 'hidden'">
                        <ul>
                            <li>
                                <a [routerLink]=" ['/dashboard/surveys']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Surveys
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/survey/reports']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Survey Reports
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/survey/exception-report']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Exception Report
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <a [routerLink]=" ['/dashboard/sales']" routerLinkActive="router-link-exact-active">
                    <fa-icon icon="chart-bar"></fa-icon>
                    Live Stock
                </a>

                <div>
                    <a (click)="toggleSubmenu($event, '/dashboard/stores')">
                        <fa-icon icon="users"></fa-icon>
                        Customers
                    </a>

                    <div [@submenu]="isSubmenuActive('/dashboard/stores') ? 'visible': 'hidden'">
                        <ul>
                            <li>
                                <a [routerLink]=" ['/dashboard/stores']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Customers
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/store-attributes']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Channel Types
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/not-visited-store-report']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Exception Report
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <a (click)="toggleSubmenu($event, '/dashboard/products')">
                        <fa-icon icon="shopping-basket"></fa-icon>
                        Products
                    </a>

                    <div [@submenu]="isSubmenuActive('/dashboard/products') ? 'visible': 'hidden'">
                        <ul>
                            <li>
                                <a [routerLink]=" ['/dashboard/products']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Products
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/product-categories']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Product Categories
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <a (click)="toggleSubmenu($event, '/dashboard/orders')">
                        <fa-icon icon="list"></fa-icon>
                        Orders
                    </a>

                    <div [@submenu]="isSubmenuActive('/dashboard/orders') ? 'visible': 'hidden'">
                        <ul>
                            <li>
                                <a [routerLink]=" ['/dashboard/orders']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Orders
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <a class="layout-menuitem-text" (click)="toggleSubmenu($event, '/dashboard/documents')">
                        <fa-icon icon="file"></fa-icon>
                        Documents
                    </a>

                    <div [@submenu]="isSubmenuActive('/dashboard/documents') ? 'visible': 'hidden'">
                        <ul>
                            <li>
                                <a [routerLink]=" ['/dashboard/documents']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Documents
                                </a>
                                <a [routerLink]=" ['/dashboard/document-dirs']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Document Folders
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <a class="layout-menuitem-text" (click)="toggleSubmenu($event, '/dashboard/photos')">
                        <fa-icon icon="camera"></fa-icon>
                        Photos
                    </a>

                    <div [@submenu]="isSubmenuActive('/dashboard/photos') ? 'visible': 'hidden'">
                        <ul>
                            <li>
                                <a [routerLink]=" ['/dashboard/photos/tags']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manage Tags
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/photos']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Photo Reports
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/photos-gallery']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Photo Gallery
                                </a>
                            </li>
                            <li>
                                <a [routerLink]=" ['/dashboard/photos-manual-upload']"
                                   routerLinkActive="router-link-exact-active"
                                   [routerLinkActiveOptions]="{exact:true}">
                                    Manual Upload
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <a [routerLink]=" ['/dashboard/store-notes']" routerLinkActive="router-link-exact-active">
                    <fa-icon icon="cog"></fa-icon>
                    Store Notes
                </a>

                <a [routerLink]=" ['/dashboard/company-settings']" routerLinkActive="router-link-exact-active">
                    <fa-icon icon="cog"></fa-icon>
                    Settings
                </a>

            </div>

        </div>
    </div>
</div>
